import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import firebase from 'firebase';
import * as firebase from "firebase/app"
// Initialize Firebase
import db from '../src/firebase.config';

import "../src/bootstrap.css";
import "../src/main.css";

import Home from './Pages/Home';
import Media from './Pages/Media';
import Temp from './Pages/Temp.js';
import User from './Pages/User';
import Invite from './Pages/Invite';
import Folder from './Pages/Folder';

// import Read from './Pages/Read';
// import One from './Pages/One';
// import RouteTest from './Pages/RouteTest';


function App() {
  return (


    <Router>
      <Routes>
        <Route exact path="/" element={<Home title="CliqRex"/>} />
        <Route path="/media/*" element={<Media  title="CliqRex Media" />} />
        <Route path="/user/*" element={<User  title="CliqRex User"/>} />
        <Route path="/temp/*" element={<Temp  title="CliqRex temp"/>} />
        <Route path="/invite/*" element={<Invite  title="CliqRex Invite"/>} />
        <Route path="/folder/*" element={<Folder  title="CliqRex Folder"/>} />
        {/* <Route path="/read" element={<Read />} />
        <Route path="/one" element={<One />} />
        <Route path="/routeTest" element={<RouteTest />} /> */}

      </Routes>
    </Router>
  );
}


export default App;