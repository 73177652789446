import {useState} from 'react';
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Download from  '../Components/Download'
import React from 'react';
//import { useSearchParams } from 'react-router-dom';
import db from '../firebase.config';
//import { createCanvas, loadImage, registerFont, Image } from 'canvas'
import Container from 'react-bootstrap/Container';
//import { Link } from "react-router-dom";
// import { SuperSEO } from 'react-super-seo';
//import { generateMediaPreview } from '../../src/lib/generatePreview'
import Helmet from 'react-helmet'
console.log ('[[MEDIA]]');

var photoUrl = require('../img/user.png'); // with require
var userName = ''


const Media = () => {
  
//http://localhost:3000/media/tv2742?ref=5BYR6Hk7WYMlf96Ur4l2AVRNQeh2
//http://localhost:3000/media/podcast554087?ref=5BYR6Hk7WYMlf96Ur4l2AVRNQeh2
//http://localhost:3000/media/book9780063251991?ref=5BYR6Hk7WYMlf96Ur4l2AVRNQeh2

//NEW
// MOVIE
// /media?ref=5BYR6Hk7WYMlf96Ur4l2AVRNQeh2&actionId=movie129
// TV
// /media?ref=5BYR6Hk7WYMlf96Ur4l2AVRNQeh2&actionId=tv98177


  const url = window.location.pathname;
  
  var mediaId = window.location.pathname.split("/").pop();
  
  var actionId;
  
  const params = new URLSearchParams(window.location.search)  
  let ref = ''; let src = null; let displayDownload = false;
  if ( params.has('ref') ) { ref = params.get('ref') }
  if ( params.has('src') ) { src = params.get('src') }
  if ( params.has('actionId') ) { mediaId = params.get('actionId') }
  if ( src === 'content' ) displayDownload = true;

  const openUrl = "https://share.cliqrex.com" + url + "?ref=" + ref + "&src=content" ;
  
  console.log('actionId: ' + actionId);
  console.log('media: ' + mediaId);
  console.log('ref: ' + ref);
  console.log("openUrl: " + openUrl)

  let mediaType = "tmdb";
  if (mediaId.includes('tv') || mediaId.includes('movie')) { mediaType = 'tmdb'; }
  if (mediaId.includes('podcast') ) { mediaType = 'podcast'; }
  if (mediaId.includes('book') ) { mediaType = 'book'; }

  
  const [info , setInfo] = useState([]);
    window.addEventListener('load', () => {
        Fetchdata();
      });
 
    // Fetch the required data using the get() method
    const Fetchdata = async ()=>{
      
      // user info from ref (referrer)
      if (ref.length > 5) {
          db.collection("users").doc( ref ).get().then((fbDocumentSnapshot) => {    
            if(fbDocumentSnapshot.exists)
            {
              var data = fbDocumentSnapshot.data();
              photoUrl = data.photoUrl;
              userName = data.displayName;
            } //if
        });// then
      } // if ref.length

      if (mediaType === "tmdb" ) {  
        db.collection("tmdb").doc( mediaId ).get().then((fbDocumentSnapshot) => {    
          console.log('tmdb load')
          if(fbDocumentSnapshot.exists)
          {
            var data = fbDocumentSnapshot.data();
            setInfo(arr => [...arr , data]);
          } //if
        });// then

        //media.posterPath
        //media.title}
        //media.watchProviders
        //{media.contentRating}
        //{media.vote_average}
        //let preview = await generateMediaPreview(media)

      } //tmdb
      
      if (mediaType === "podcast") {
        var id = mediaId.replace("podcast", "");
        //console.log('podcast load: '+ id)
        db.collection("podcast").doc( id ).get().then((fbDocumentSnapshot) => {    
          if(fbDocumentSnapshot.exists)
          {
            var data = fbDocumentSnapshot.data();
            setInfo(arr => [...arr , data]);
          } //if
        });// then
      } //podcast

      
      if (mediaType === "book") {
        id = mediaId.replace("book", "");
        //console.log('book load: '+ id)
        db.collection("books").doc( id ).get().then((fbDocumentSnapshot) => {    
          if(fbDocumentSnapshot.exists)
          {
            var data = fbDocumentSnapshot.data();
            setInfo(arr => [...arr , data]);
            console.log(data)
          } //if
        });// then
      } //book

    } //fetch
     
var download = ""
if (displayDownload) download = "Looks like you don't have CliqRex installed.";

    // Display the results on the page
    if (mediaType === "tmdb" || mediaType === "podcast") {

      console.log('mediatype tmdb or podcast load card')

      return (
        
        <div>
          <Header />
            <center>
            {
            info.map((data) => (
              <Card   
                download = {download}
                openUrl = {openUrl}
                photoUrl = {photoUrl}
                userName = {userName}
                title={data.title}
                type={data.type}
                img={data.posterPath}
                subTitle=""
                 deets={data.overview}
                release={data.releaseDate} 
              
              />
            ))
          }
        </center>     
        </div>
 
    );
    } // tmdb or pod

    if (mediaType === "book" ) {
      return (
        <div>
          <Header />
            <center>
            {
            info.map((data) => (
            <Card   
                    download = {download}
                    openUrl = {openUrl}
                    photoUrl = {photoUrl}
                    userName = {userName}
                    title={data.volumeInfo.title}
                    subTitle={data.volumeInfo.subTitle}
                    type=""
                    img={data.volumeInfo.imageLinks.thumbnail}
                     deets={data.searchInfo.textSnippet}
                    release={data.volumeInfo.publishedDate}
                    />
            ))
          }
        </center>     
        </div>
 
    );
    } // tmdb or pod

}

console.log('here')

const Card  = ({download, openUrl,photoUrl, userName,title , subTitle, status , type, img, deets, release}) => {
    console.log("Card -- " + title + " " + status + " " + type);
    return (

      <Container className="p-4">
        <Helmet>
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={subTitle}/>
          <meta property="og:image" content={img}/>
        </Helmet>
      <Container className="px-5 pt-4 pb-5 mb-4 bg-light rounded-3">

      <div className="pb-3">
      <img alt='' className='img-fluid p20 py-1 userImage' src={photoUrl}></img>
      <br></br>
 
      <span className='userName'>{userName} shared {title}</span>.

      </div>
      <br></br>
     <Download />
 
      <a href={openUrl} rel='noreferer' target="_blank"><img className='img-fluid rounded-3 media' src={img}></img></a>
             
                <h1 className="py-4">{title}</h1>  
                <b>{subTitle}</b> 
                {/* <p>{release}</p>     */}
                         
  <p>{deets}</p>  
                <h4 className='blue'>{download}</h4>                            

                <Footer />
        </Container>
        </Container>
         

    );
}
 
export default Media;
