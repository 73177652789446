import {useState} from 'react';
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useSearchParams } from 'react-router-dom';
import db from '../firebase.config';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import chalk from 'chalk';

const log = console.log;
log(chalk.green('Folder.js') + ' yehhhh boy' + chalk.red('!'));
var photoUrl = require('../img/user.png'); // with require
var userName = ''

const _userData = db.collection("users");
const _customListData = db.collection("customLists");

const _globalShowData = db.collection("mediaData");
const _globalReportData = db.collection("reports");

const Folder = () => {

//share.cliqrex.com/folder/:id?ref=:uid
//share.cliqrex.com/folder/:id?ref=5BYR6Hk7WYMlf96Ur4l2AVRNQeh2

  const url = window.location.pathname;
  
  const userId = window.location.pathname.split("/").pop();
  const params = new URLSearchParams(window.location.search)  
  let ref = ''; let src = null; let displayDownload = false;
  if ( params.has('ref') ) { ref = params.get('ref') }
  if ( params.has('src') ) { src = params.get('src') }
  if ( src === 'content' ) displayDownload = true;

  const openUrl = "https://share.cliqrex.com" + url + "?ref=" + ref + "&src=content" ;
  
  console.log('userId: ' + userId);
  console.log('ref: ' + ref);
  console.log("openUrl: " + openUrl)
  
const [info , setInfo] = useState([]);

window.addEventListener('load', () => {
    Fetchdata();
    });

// Fetch the required data using the get() method
const Fetchdata = async ()=>{
    log('fetchit')
    
    // user info from ref (referrer)
    if (ref.length > 5) {
        db.collection("users").doc( ref ).get().then((fbDocumentSnapshot) => {    
            if(fbDocumentSnapshot.exists)
            {
                var data = fbDocumentSnapshot.data();
                photoUrl = data.photoUrl;
                userName = data.displayName;
            } //if
        });// then
    } // if ref.length

    console.log('**folder load: '+ userId)
    db.collection("users").doc( userId ).get().then((fbDocumentSnapshot) => {    
        if(fbDocumentSnapshot.exists)
        {
            var data = fbDocumentSnapshot.data();
            setInfo(arr => [...arr , data]);
            console.log(data)
        } //if
    });// then

    

} //fetch
 
var download = ""
if (displayDownload) download = "Looks like you don't have CliqRex installed.";

// Display the results on the page
    return (
    <div>

       

        <Header />
        <center>
        {
        info.map((data) => (
        <Card   
                download = {download}
                openUrl = {openUrl}
                photoUrl={data.photoUrl}
                displayName={data.displayName}
                userName={data.username}
                
                />
        ))
        }
    </center>     
    </div>

);
  
    }
    const Card  = ({download, openUrl, photoUrl, displayName, userName}) => {
        //console.log(title + " " + status + " " + type);
        return (
            
        <Container className="p-4">
        <Container className="px-5 pt-4 pb-5 mb-4 bg-light rounded-3">

        <div className="pb-3">
        <img className='img-fluid p20 py-1 userImage' src={photoUrl}></img>
        <br></br>
    
        <span className='userName'>{userName}</span>  <span className="white">{displayName}</span>.

        </div>
        
        <h4 className='blue'>{download}</h4>                            

        <Footer />
        </Container>
        </Container>
            

        );
    }

  
    

    
export default Folder;
