import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyBv_EGu5FQvwhrPBg3TEFal2UQQXNtD12M",
    authDomain: "cliqrex.firebaseapp.com",
    databaseURL: "https://cliqrex-default-rtdb.firebaseio.com",
    projectId: "cliqrex",
    storageBucket: "cliqrex.appspot.com",
    messagingSenderId: "1015152907384",
    appId: "1:1015152907384:web:0ba94ba1a17dea80fbc98d",
    measurementId: "G-B57FRSM9W4"
  };
  
const firebaseApp=firebase.initializeApp(firebaseConfig);
const db=firebase.firestore();

export default db;
