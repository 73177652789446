import React, { Component } from 'react'  

const appStore = require('../img/app-store.png'); // with require
const googlePlay = require('../img/google-play.png'); // with require
var ios = "https://apps.apple.com/us/app/cliqrex/id1628183870";
var google = "https://play.google.com/store/apps/details?id=com.pixaura.cliqrex";


export class Download extends Component {  

    render() {  
        return ( 
          
          <div>  
            <div className="mb-2">
              <a href={ios} target="_blank"><img className="pb-3 px-3" src={ appStore }></img></a>
              <a href={google} target="_blank"><img className="pb-3 px-3" src={ googlePlay }></img></a>
            </div>
              
            
          </div>  
        )  
    }  
}  
  
export default Download  
