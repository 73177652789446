import React, { Component } from 'react'  
import { Link } from "react-router-dom";

//https://justinmahar.github.io/react-super-seo/#quick-start


const logo = require('../img/cliqrex-sm.png'); // with require


export class Header extends Component {  
    render() {  
        return (  
            
            <div>
                <div className="mt-4 text-center" >  
                    <Link to="/"><img src={ logo }></img></Link>
                </div>
            </div>
              
        )  
    }  
}  
  
export default Header  