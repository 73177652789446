import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import TopFolders from '../Components/TopFolders'
import { useSearchParams } from 'react-router-dom';
import db from '../firebase.config';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import chalk from 'chalk';

// firestore
const _userData = db.collection("users");
const _customListData = db.collection("customLists");
const _globalShowData = db.collection("mediaData");
const _globalReportData = db.collection("reports");


const log = console.log;
log(chalk.blue('User.js') + ' we are' + chalk.red('!'));
var photoUrl = require('../img/user.png'); // with require
var userName = ''
var tvList = [];
var theMediaList = [];
var topList = [];
var tvMedia;
var userData;

const Temp = () => {
  
//http://localhost:3000/user5BYR6Hk7WYMlf96Ur4l2AVRNQeh2

  const url = window.location.pathname;
  
  const userId = window.location.pathname.split("/").pop();
  const params = new URLSearchParams(window.location.search)  
  let ref = ''; let src = null; let displayDownload = false;
  
  if ( params.has('ref') ) { ref = params.get('ref') }
  if ( params.has('src') ) { src = params.get('src') }
  if ( src === 'content' ) displayDownload = true;
  var download = ""
  if (displayDownload) download = "Looks like you don't have CliqRex installed.";
  

  const openUrl = "https://share.cliqrex.com" + url + "?ref=" + ref + "&src=content" ;
 

  //console.log('userId: ' + userId);
  //console.log('ref: ' + ref);
  //console.log("openUrl: " + openUrl)
  
const [info , setInfo] = useState([]);

window.addEventListener('load', () => {
    log(chalk.yellow('eventlistener'))
    FetchData();
});

// Fetch the required data using the get() method
const FetchData = async ()=>{

    var userData
   

    //console.log('**user load: '+ userId)
    db.collection("users").doc( userId ).get().then((fbDocumentSnapshot) => {    
        if(fbDocumentSnapshot.exists)
        {
            userData = fbDocumentSnapshot.data();
            setInfo(arr => [...arr , userData]);    
        } //if
    });// then

    // user info from ref (referrer)
    if (ref.length > 5) {
        db.collection("users").doc( ref ).get().then((fbDocumentSnapshot) => {    
            if(fbDocumentSnapshot.exists)
            {
                var refData = fbDocumentSnapshot.data();
                photoUrl = refData.photoUrl;
                userName = refData.displayName;
            } //if
        });// then
    } // if ref.length

    //GET THE TOP MEDIA
    const topMedia = await GetUserTopMedia(userId);
    console.log(chalk.blue('-topmedia-'))
    
    topList = [];
    topList = await TopMediaItems(topMedia);

    // Display the results on the page
    log(chalk.green('[] topList-table'));
    console.table(topList)
    log(chalk.green('[] topList-log'));
    console.log(topList)
 
    // tvList.push('abc');
    // tvList.push('def');
    // tvList.push('ghi');
 
    // const mediaList = tvList.map((item) =>
    //   <li>{item}</li>
    //  );
    
    //object
    // const tvMedia = tvList.map((item) =>
    // <li>{item.img}</li>
    //   );

    // item
    // var tvMedia = tvList.map((img) =>
    // <li>img: {img} </li>
    // );

    log(chalk.green('tvList --- map'));
    //console.log(tvList);

    log(chalk.red(' --userData'))
    //console.table(userData)

    return (
    <div>
        <Header />
        <center>
        {/* {
        info.map((userData) => (
        <Card   
                download = {download}
                openUrl = {openUrl}
                photoUrl={userData.photoUrl}
                displayName={userData.displayName}
                userName={userData.username} 
                />
        ))
        } */}
        {/* <ul>{mediaList}</ul> */}

    </center>


    </div>
    );

        log(chalk.red('gotthrough'))

}//fetchdata


    const Card  = ({download, openUrl, photoUrl, displayName, userName}) => {
        console.log(download + " " + download + " " + displayName);
        return (  
        <Container className="p-4">
        <Container className="px-5 pt-4 pb-5 mb-4 bg-light rounded-3">
        <div className="pb-3">
        <img className='img-fluid p20 py-1 userImage' src={photoUrl}></img>
        <br></br>
        <span className='userName'>{userName} @{displayName}</span>
        </div>
        <h4 className='blue'>{download}</h4>                            
        <Footer />
        </Container>
        </Container>
        );
    }

   

    const GetUserTopMedia = async (userId) => {
    
        let uid = userId;
    
        let tv = [];
        let movie = [];
        let album = [];
        let podcast = [];
        let book = [];
        
        log(chalk.blue('get user topmedia') + uid)
        let snapUserMedia = await _userData.doc(uid).collection("showList").where("userRating", "!=", "").get();
        let docsUserMedia = snapUserMedia.docs;
        
        docsUserMedia.sort((a, b) => b.get("userRating") - a.get("userRating"));
        
        log(chalk.magenta('doc item'));
        var data;

        for (let index = 0; index < docsUserMedia.length; index++) {
            const doc = docsUserMedia[index];
            let rateData = doc.get("userRating");
            let userRating = parseFloat(rateData);

            if (doc.id.includes("tv") && tv.length < 4) {
                tv.push(doc.id);
            }
            if (doc.id.includes("movie") && movie.length < 4) {
                movie.push(doc.id);
            }
            if (doc.id.includes("album") && album.length < 4) {
                album.push(doc.id);
            }
            if (doc.id.includes("podcast") && podcast.length < 4) {
                podcast.push(doc.id);
            }
            if (doc.id.includes("book") && book.length < 4) {
                podcast.push(doc.id);
            }

        

        } //for

        // console.log(chalk.yellow('--tv array'));
        // console.table(tv);

        var resData = {
            tv: tv,
            movie: movie,
            podcast: podcast,
            album: album,
            book: book
        };

        //console.table(resData)
        return resData;
    };

    const TopMediaItems = async (topMedia) => {
        console.log(chalk.blue('--topMedia'))
        console.table(topMedia)
        
        theMediaList = [];

        for (let i = 0; i < topMedia['tv'].length; i++) {
            const mediaId = topMedia['tv'][i]
            console.log(" mediaId: " + mediaId )
                db.collection("tmdb").doc( mediaId ).get().then((fbDocumentSnapshot) => {    
                if(fbDocumentSnapshot.exists)
                {
                    var tmdbData = fbDocumentSnapshot.data();
                    var imgUrl = tmdbData.posterPath;
                    // const media = {id: mediaId, img: imgUrl};
                    // tvList.push ( media );   
                    tvList.push ( imgUrl );   
                    //tvList.push ( mediaId );   
                } //
            });// then
          } //for
        } //fetchdata

        //log..
        console.log(chalk.blue(' -(( tvList ))- '))
        console.table(tvList)

        return tvList;     
    }


    const getUserCustomLists = async (userId) => {
        let uid = userId;
      
        let lists = [];
    
        let snapUserCustomLists = await _userData.doc(uid).collection("customLists").get();
        let docsUserCustomLists = snapUserCustomLists.docs;
        for (let index = 0; index < docsUserCustomLists.length; index++) {
            const doc = docsUserCustomLists[index];
            //TODO: Sort custom
            lists.push(doc.id);
        }
    
        var resData = {
            uid: uid,
            lists: lists,
        };
    
        
        return resData;
    };


export default Temp;


//// END OF THE FN WORLD>>>> 

    //console.table(doc)

            //TRIED TO PUSH OBJECTS... 
            // if (doc.id.includes("tv") && resData['tv'].length < 5) {
            //     db.collection("tmdb").doc( doc.id ).get().then((fbDocumentSnapshot) => {    
            //         if(fbDocumentSnapshot.exists) { 
            //             console.log(chalk.redBright('----data---'))
            //             data = fbDocumentSnapshot.data();
            //             //console.table(data); 
            //             tv.push( {data} ); 
            //         }
            //     });// then
                
            // }

            // if (doc.id.includes("movie") && movie.length < 5) {
            //     db.collection("tmdb").doc( doc.id ).get().then((fbDocumentSnapshot) => {    
            //         if(fbDocumentSnapshot.exists) { data = fbDocumentSnapshot.data(); } //if
            //     });// then
            //     //movie.push( data );
            // }
    
            // if (doc.id.includes("album") && album.length < 5) {
            //     db.collection("tmdb").doc( doc.id ).get().then((fbDocumentSnapshot) => {    
            //         if(fbDocumentSnapshot.exists) { data = fbDocumentSnapshot.data(); } //if
            //     });// then
            //     //album.push(data);
            // }
    
            // if (doc.id.includes("podcast") && podcast.length < 5) {
            //     db.collection("tmdb").doc( doc.id ).get().then((fbDocumentSnapshot) => {    
            //         if(fbDocumentSnapshot.exists) { data = fbDocumentSnapshot.data(); } //if
            //     });// then
            //     //podcast.push(data);
            // }
            // if (doc.id.includes("book") && book.length < 5) {
            //     db.collection("tmdb").doc( doc.id ).get().then((fbDocumentSnapshot) => {    
            //         if(fbDocumentSnapshot.exists) { data = fbDocumentSnapshot.data(); } //if
            //     });// then
            //     //book.push( data);
            // }


