import React, { Component } from 'react'  
import Download from  '../Components/Download'
const dino = require('../img/dino.png'); // with require
const power = require('../img/powerful.png'); // with require

var currentDate = new Date();
var year = currentDate.getFullYear();
//const year = '2022';



export class Footer extends Component {  

    render() {  
        return ( 
          
          

          
          <div>  
            <footer className="sticky-footer">  
              <div className="container my-auto">  
              <div className="text-center my-auto">  

              <h5 className="pt-3">Download CliqRex: </h5>
              <br></br>
             
              <Download />
             
              <p className='white'>...to find, save, and share favs with your friends.</p>

              <img className="py-3  img-fluid" src={ dino } ></img>
              <br></br>
              <img className="py-3 img-fluid" src={ power } ></img>
              <br></br>

              <span>CliqRex© {year}</span>  
              </div>  
              </div>  
            </footer>  
          </div>  
        )  
    }  
}  
  
export default Footer  


