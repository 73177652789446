import React from 'react';
import { Link } from "react-router-dom";
import Header  from '../Components/Header'   
import Footer  from '../Components/Footer'   
import { SuperSEO } from "react-super-seo";

// const userImg = require('../img/user.png'); // with require
const dino = require('../img/dino.png'); // with require
const power = require('../img/powerful.png'); // with require

export default function Page() { 
  
  console.log('home')

  return (  
    <div>

      <Header />


      <SuperSEO
  title="Home | React Super SEO"
  description="React SEO component with OpenGraph and Twitter Cards support."
  lang="en"
  openGraph={{
    ogImage: {
      ogImage: "http://placekitten.com/1200/630",
      ogImageAlt: "Kittens",
      ogImageWidth: 1200,
      ogImageHeight: 630,
      ogImageType: "image/jpeg",
    },
  }}
  twitter={{
    twitterSummaryCard: {
      summaryCardImage: "http://placekitten.com/1200/630",
      summaryCardImageAlt: "Kittens",
      summaryCardSiteUsername: "justinmahar",
    },
  }}
/>

        <div className="container my-auto">  
        <div className="text-center my-auto">  
        <img src={ dino } ></img>
        <br></br>
        {/* <Link to="Read">Read</Link> */}
        {/* <Link to="One">One</Link> */}
        <Link to="https://cliqrex.com">cliqrex.com</Link>
        <br></br>
        <br></br>
        <img src={ power } ></img>
<br></br>
        </div>  
        </div>  

      <Footer />  
    </div>
    
      

  );  
}  
