import {useState} from 'react';
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useSearchParams } from 'react-router-dom';
import db from '../firebase.config';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import chalk from 'chalk';
import Download from  '../Components/Download'


const phone = require('../img/cliqrex-phone.png'); // with require
const log = console.log;
log(chalk.blue('USER.js') + ' we are' + chalk.red('!'));
var photoUrl = require('../img/user.png'); // with require
var userName = ''

const _userData = db.collection("users");
const _customListData = db.collection("customLists");
const _globalShowData = db.collection("mediaData");





const User = () => {

let topList = [];
let mediaList = [];
let topMedia = [];
//http://localhost:3000/user5BYR6Hk7WYMlf96Ur4l2AVRNQeh2

  const url = window.location.pathname;  
  var userId = window.location.pathname.split("/").pop();
  const params = new URLSearchParams(window.location.search)  
  let ref = ''; let src = null; let displayDownload = false;
  if ( params.has('ref') ) { ref = params.get('ref') }
  if ( params.has('src') ) { src = params.get('src') }
  if ( params.has('actionId') ) { userId = params.get('actionId') }
  if ( src === 'content' ) displayDownload = true;

  const openUrl = "https://share.cliqrex.com" + url + "?ref=" + ref + "&src=content" ;
  
  console.log('userId: ' + userId);
  console.log('ref: ' + ref);
  console.log("openUrl: " + openUrl)
  
const [info , setInfo] = useState([]);
window.addEventListener('load', () => {
    Fetchdata();
    });


// Fetch the required data using the get() method
const Fetchdata = async ()=>{
    
    var userData

    console.log('**USER load: '+ userId)
    db.collection("users").doc( userId ).get().then((fbDocumentSnapshot) => {    
        if(fbDocumentSnapshot.exists)
        {
            userData = fbDocumentSnapshot.data();
            setInfo(arr => [...arr , userData]);    
        } //if
    });// then

console.table(userData)

    // // user info from ref (referrer)
    // if (ref.length > 5) {
    //     db.collection("users").doc( ref ).get().then((fbDocumentSnapshot) => {    
    //         if(fbDocumentSnapshot.exists)
    //         {
    //             var data = fbDocumentSnapshot.data();
    //             userName = data.displayName;
    //             setInfo(arr => [...arr , data]);
    //         } //if
    //     });// then
    // } // if ref.length

    
   // topmedia gets list
    GetUserTopMedia(userId).then((data)=>{ topMedia = data });
    log(chalk.red('-topMedia promise'))
    console.log(topMedia);

    // //get the values
    TopMediaItems(topMedia).then((data)=>{ topList = data });



    // // Display the results on the page
    log(chalk.green('[] topList-table'));
    // // NOT IN TABLE
    console.table(topList)

    log(chalk.green('[] topList-log'));
    // // BUT can console prototype
    console.log(topList)


    
    log(chalk.blue('-mediaList-'))
    console.log(mediaList)

} //fetch

var download = ""
if (displayDownload) download = "Looks like you don't have CliqRex installed.";

// Display the results on the page

//let invite = userName + " invite to CliqRex";

    return (
   

    <div>



        <Header />
        <center>
            {
                info.map((userData) => (
                <Card   
                download = {download}
                openUrl = {openUrl}
                photoUrl={userData.photoUrl}
                displayName={userData.displayName}
                userName={userData.username} 
                />
                ))
            }
           
         

            
            <ul>{GetTopList(topList)}</ul>
      
        </center>     
    </div>
    );
}//

function GetTopList (topList) {
    log(chalk.green('---GetTopList'))
    console.log(topList)
    return topList.map((item) =>
    <li>{item}</li>
    );

}

const Card  = ({download, openUrl, photoUrl, displayName, userName}) => {
    console.log("CARD:: " + download + " " + download + " " + displayName);
    return (  
    <Container className="p-4">
    <Container className="px-5 pt-4 pb-5 mb-4 bg-light rounded-3">
    <div className="pb-3">
    <img className='img-fluid p20 py-1 userImage' src={photoUrl}></img>
    <br></br>
    <span className='userName'>{userName} @{displayName}</span>
    </div>
    <h4 className='blue'>{download}</h4>                            
    <Footer />
    </Container>
    </Container>
    );
}

const GetUserTopMedia = async (userId) => {

    let uid = userId;

    let tv = [];
    let movie = [];
    let album = [];
    let podcast = [];
    let book = [];
    
    log(chalk.blue('get user topmedia') + uid)
    let snapUserMedia = await _userData.doc(uid).collection("showList").where("userRating", "!=", "").get();
    let docsUserMedia = snapUserMedia.docs;
    
    docsUserMedia.sort((a, b) => b.get("userRating") - a.get("userRating"));
    
    log(chalk.magenta('doc item'));
    var data;

    for (let index = 0; index < docsUserMedia.length; index++) {
        const doc = docsUserMedia[index];
        let rateData = doc.get("userRating");
        let userRating = parseFloat(rateData);

        if (doc.id.includes("tv") && tv.length < 4) {
            tv.push(doc.id);
        }
        if (doc.id.includes("movie") && movie.length < 4) {
            movie.push(doc.id);
        }
        if (doc.id.includes("album") && album.length < 4) {
            album.push(doc.id);
        }
        if (doc.id.includes("podcast") && podcast.length < 4) {
            podcast.push(doc.id);
        }
        if (doc.id.includes("book") && book.length < 4) {
            podcast.push(doc.id);
        }
    } //for

    // console.log(chalk.yellow('--tv array'));
    // console.table(tv);

    var resData = {
        tv: tv,
        movie: movie,
        podcast: podcast,
        album: album,
        book: book
    };

    //console.table(resData)
    return resData;
};

const TopMediaItems = async (topMedia) => {
    console.log(chalk.blue('--topMedia'))
    console.table(topMedia)

    let theMediaList = [];
    for (let i = 0; i < topMedia['tv'].length; i++) {
        const mediaId = topMedia['tv'][i]
        console.log(" mediaId: " + mediaId )
            db.collection("tmdb").doc( mediaId ).get().then((fbDocumentSnapshot) => {    
            if(fbDocumentSnapshot.exists)
            {
                var tmdbData = fbDocumentSnapshot.data();
                var imgUrl = tmdbData.posterPath.toString();
                log(chalk.blue(imgUrl))
                // const media = {id: mediaId, img: imgUrl};
                // tvList.push ( media );   
                theMediaList.push ( imgUrl );   
                //tvList.push ( mediaId );   
            } //
        });// then
      } //for

    //log..
    console.log(chalk.blue(' -(( tvList ))- '))
    console.table(theMediaList)

    return theMediaList;  
    } //fetchdata   

const getUserCustomLists = async (userId) => {
    let uid = userId;
  
    let lists = [];

    let snapUserCustomLists = await _userData.doc(uid).collection("customLists").get();
    let docsUserCustomLists = snapUserCustomLists.docs;
    for (let index = 0; index < docsUserCustomLists.length; index++) {
        const doc = docsUserCustomLists[index];
        //TODO: Sort custom
        lists.push(doc.id);
    }

    var resData = {
        uid: uid,
        lists: lists,
    };

    
    return resData;
};



////////////////////////
export default User;
