import {useState} from 'react';
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useSearchParams } from 'react-router-dom';
import db from '../firebase.config';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import chalk from 'chalk';
// import { SuperSEO } from 'react-super-seo';
import Download from  '../Components/Download'


const phone = require('../img/cliqrex-phone.png'); // with require


const log = console.log;
log(chalk.blue('Invite.js') + ' we are' + chalk.red('!'));
var photoUrl = require('../img/user.png'); // with require
var userName = ''

const _userData = db.collection("users");
const _customListData = db.collection("customLists");
const _globalShowData = db.collection("mediaData");

const Invite = () => {
  
//http://localhost:3000/user5BYR6Hk7WYMlf96Ur4l2AVRNQeh2

  const url = window.location.pathname;
  
  const userId = window.location.pathname.split("/").pop();
  const params = new URLSearchParams(window.location.search)  
  let ref = ''; let src = null; let displayDownload = false;
  if ( params.has('ref') ) { ref = params.get('ref') }
  if ( params.has('src') ) { src = params.get('src') }
  if ( src === 'content' ) displayDownload = true;

  const openUrl = "https://share.cliqrex.com" + url + "?ref=" + ref + "&src=content" ;
  
  console.log('userId: ' + userId);
  console.log('ref: ' + ref);
  console.log("openUrl: " + openUrl)
  
const [info , setInfo] = useState([]);
window.addEventListener('load', () => {
    Fetchdata();
    });

// Fetch the required data using the get() method
const Fetchdata = async ()=>{
    
    // user info from ref (referrer)
    if (ref.length > 5) {
        db.collection("users").doc( ref ).get().then((fbDocumentSnapshot) => {    
            if(fbDocumentSnapshot.exists)
            {
                var data = fbDocumentSnapshot.data();
                userName = data.displayName;
                setInfo(arr => [...arr , data]);



            } //if
        });// then
    } // if ref.length

  

    // var topMedia = getUserTopMedia(userId);
    // console.log(topMedia)

} //fetch
 
var download = ""
if (displayDownload) download = "Looks like you don't have CliqRex installed.";

// Display the results on the page

let invite = userName + " invite to CliqRex";

    return (


        
        <div>



            <Header />

            <center>
                {
                info.map((data) => (              
                <Card   
                        download = {download}
                        openUrl = {openUrl}
                        photoUrl={data.photoUrl}
                        displayName={data.displayName}
                        userName={data.username}
                        />
                ))
                }
            </center>     
        </div>
    );

    }
    const Card  = ({download=null, openUrl=null, photoUrl, displayName, userName}) => {
        log(chalk.red(displayName));
        return (
            
        <Container className="p-4">
        <Container className="px-3 pt-4 pb-5 mb-4 bg-light rounded-3">

        <div className="">
        <img className='img-fluid p20 py-3 userImageInvite' src={photoUrl}></img>
        <br></br>
    
        <p><span className='userName'>{displayName} invited you to join CliqRex. </span></p> 
        
        <p>CliqRex is a free app to Discover, Save, and Share TV Shows, Movies, Books, and Podcasts</p>
        </div>
    

        <h4 className='blue'>{download}</h4>                            
        <div>
       
        </div>

        <Footer />
<br></br>
<img className='phone' src={phone}></img>
<br></br>
        <p>
        Ever get a show recommendation from a friend and couldn't remember what it was when you sat down to watch something? Do you have a list of movies in your Notes app and can't recall who made the suggestions? Do you feel overwhelmed with all the options on your streaming services? Have you ever forgotten what you were watching 3 months ago? We have! So, we made this app to solve these problems and it's been really fun to use.

        </p>

        </Container>
        </Container>
            

        );
    }

    
export default Invite;
